.infopanel-container {
  display: flex;
  justify-content: center;

  position: absolute;
  bottom: 12px;
  width: 100%; 

  background: transparent;
}

.infopanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 500px;
  min-width: 275px;
  margin-left: 9px;
  margin-right: 9px;

  padding-left: 12px;
  padding-right: 12px;
  
  background: #FAFAFA;

  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
}

.infopanel-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  background-color: transparent;
  
  top: -32px;
}

.infopanel-logo {
  height: 64px
}

.infopanel-close-button-container {
  display: flex;
  margin-top: 12px;
  width: 100%;
}

.infopanel-close-button {
  height: 10px;
}

.infopanel-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  margin-top: 25px;
}

.infopanel-title {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  padding-bottom: 10px;
}

.infopanel-title p {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;

  margin: 0 auto;

  color: #29323C;
}

.infopanel-description {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  margin-bottom: 16px;
 }

.infopanel-description p {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;

  margin: 0 auto;

  color: #29323C;
}

.button-container {
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 375px) {
  .infopanel {
    width: 100%;
  }
}