.singlemarker-info-container {
  color: black;
  font-size: 14px;
  font-family: 'Avenir';
  border-bottom-width: 2px;
  text-align: left;
}

.singlemarker-info-container h3 {
  text-align: center;
}