.button {
  display: flex;
  justify-content: center;
  align-items: center;

  background: #F7B328;
  width: 100%;
  height: 50px;

  box-shadow: 0px 3px 0px #E99C00;
  border-radius: 4px;

  font-family: 'Phosphate-Solid';
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 0px;

  color: #FFFFFF;
}

.button a {
  color: #FFFFFF;
  text-decoration: none;
}

@media (max-width: 375px) {
  .button {
    height: 35px;
  }
}