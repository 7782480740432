.infobanner-container {
  display: flex;
  justify-content: center;

  position: absolute;
  top: 38px;
  width: 100%; 

  background: transparent;
}

.infobanner {
  height: 45px;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 9px;
  margin-right: 9px;
  
  background: #FAFAFA;

  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.15);
  border-radius: 6px;

  font-family: 'Avenir';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
}