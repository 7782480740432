html, body {margin: 0; height: 100%; overflow: hidden}

.App {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.App-body {
  flex: 1;
  width: 100%;
}

.App-noride {
  background-color: lightgray;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
