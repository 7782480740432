@font-face {
  font-family: 'Phosphate-Solid';
  src: url('./font/phosphate-solid.eot');
  src: url('./font/phosphate-solid.eot?#iefix') format('embedded-opentype'),
    url('./font/phosphate-solid.woff2') format('woff2'),
    url('./font/phosphate-solid.woff') format('woff'),
    url('./font/phosphate-solid.ttf') format('truetype'),
    url('./font/phosphate-solid.svg#phosphate-solid') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Black';
  src: url('./font/AvenirLTStd-Black.eot');
  src: url('./font/AvenirLTStd-Black.eot?#iefix') format('embedded-opentype'),
    url('./font/AvenirLTStd-Black.woff2') format('woff2'),
    url('./font/AvenirLTStd-Black.woff') format('woff'),
    url('./font/AvenirLTStd-Black.svg#avenir-black') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Book';
  src: url('./font/AvenirLTStd-Book.eot');
  src: url('./font/AvenirLTStd-Book.eot?#iefix') format('embedded-opentype'),
    url('./font/AvenirLTStd-Book.woff2') format('woff2'),
    url('./font/AvenirLTStd-Book.woff') format('woff'),
    url('./font/AvenirLTStd-Book.svg#avenir-book') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir-Medium';
  src: url('./font/AvenirLTStd-Medium.eot');
  src: url('./font/AvenirLTStd-Medium.eot?#iefix') format('embedded-opentype'),
    url('./font/AvenirLTStd-Medium.woff2') format('woff2'),
    url('./font/AvenirLTStd-Medium.woff') format('woff'),
    url('./font/AvenirLTStd-Medium.svg#avenir-medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
